<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
            <strong>在庫棚卸し</strong>
        </CCardHeader>

        <vue-confirm-dialog></vue-confirm-dialog>

        <CCardBody>
          <div>
            <CRow>
              <CCol sm="4">
                <CSelect
                    id="status"
                    label="ステータス"
                    horizontal
                    v-model="statusSelected"
                    :options="statusOptions"
                    @change="selectStatusChange"
                    placeholder="選択する"
                />
              </CCol>
            </CRow>
            <CRow v-if="userShopId === WAREHOUSUE">
              <CCol sm="4">
                <CSelect
                    id="shop"
                    label="店舗"
                    horizontal
                    v-model="shopSelected"
                    :options="shopOptions"
                    @change="selectShopChange"
                    placeholder="選択する"
                />
              </CCol>
            </CRow>
            <CRow>
                <CCol sm="2">
                    <CButton size="sm" color="danger" @click="resetFilterData">取り消す</CButton>
                </CCol>
            </CRow>
          </div>
          
          <CRow class="text-right mb-2" v-if="userShopId !== WAREHOUSUE">
            <CCol sm="12">
              <CButton color="primary" class="mr-2" @click="goToCreatePage">新規棚卸し</CButton>
            </CCol>
          </CRow>

          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <DataTable
              hover
              striped
              border
              :items="inventoryWorks"
              :fields="fields"
              :items-per-page="perPage"
              :active-page="page"
              :pagination-data="paginationData"
              @page-change="pageChange"
              @pagination-change="selectChangePerpage"
              :pagination="{ doubleArrows: false, align: 'center'}"
              :items-per-page-select="{
                  label: '表示件数',
                  values: optionsPerPage,
              }"
            >
              <td slot="status" slot-scope="{item}" class="text-center">
                {{ STATUS_INVENTORY_WORK[item.status] }}
              </td>
              <td slot="edit" slot-scope="{item}" class="text-center">
                <div v-if="item.status === 0">
                    <CButton color="primary" @click="() => goToEditPage(item)">編集</CButton>
                </div>
              </td>
              <td slot="show" slot-scope="{item}" class="text-center">
                <CButton color="primary" @click="() => goToSummaryPage(item)">詳細</CButton>
              </td>
              <td slot="archive" slot-scope="{item}" class="text-center">
                <div v-if="item.status === 0">
                  <CButton color="danger" @click="() => handleConfirmArchive(item)">アーカイブ</CButton>
                </div>
              </td>
            </DataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import InventoryWork from "@/views/inventory-works/InventoryWorkFields";
import DataTable from "@/components/DataTable";
import ResourceApi from "@/api/resourceApi";
import inventoryWorkApiOther from '../../api/inventoryWorkApi';
import {WAREHOUSUE, STATUS_INVENTORY_WORK} from "@/utils/constance";
import Vue from "vue";

const inventoryWorkApi = new ResourceApi('inventory-works');
const apiShop = new ResourceApi('shops');

export default {
  name: 'InventoryWorksList',
  components: {DataTable},
  data() {
    return {
      WAREHOUSUE: WAREHOUSUE,
      loading: false,
      inventoryWorks: [],
      fields: '',
      paginationData: {},
      page: 1,
      perPage: 30,
      query: {
        page: this.page,
        per_page: this.perPage,
        type_data: this.typeDataSelected
      },
      key: Math.random(),
      optionsPerPage: [10, 20, 30, 40, 50, 100, 500],
      activePage: 1,
      STATUS_INVENTORY_WORK: STATUS_INVENTORY_WORK,
      userShopId: this.$store.getters.user ? this.$store.getters.user.shop_id : '',
      shopSelected: '',
      shopOptions: [],
      statusSelected: '',
      statusOptions: [
        {label: '全て', value: ''},
        {label: '未処理', value: 0},
        {label: '棚卸し済み', value: 1}
      ],
    }
  },

  created() {
    if (this.userShopId === WAREHOUSUE) {
      this.fields = InventoryWork.listWarehouseFields();
      this.getShops();
    } else {
      this.fields = InventoryWork.listShopFields();
    }
    this.setParams()
    let params = this.getParams()
    this.getData(params);
  },

  beforeRouteEnter (to, from, next) {
    if(!from.path.includes('inventory-works')) {
      sessionStorage.removeItem('inventoryWorks')
    }
    next()
  },

  methods: {
    getData(params) {
      this.loading = true;
      this.key =  Math.random();
      this.query = {...this.query, ...params};

      let query = this.getParams()
      sessionStorage.setItem('inventoryWorks', JSON.stringify(query))

      inventoryWorkApi.index(query).then(response => {
        this.inventoryWorks = response.data.data;
        this.paginationData = response.data;
      }).finally(() => {
        this.loading = false;
      })
    },

    getParams() {
      return {
        page: this.page,
        per_page: this.perPage,
        status_equal: this.statusSelected,
        shop_id_equal: this.shopSelected,
      }
    },

    setParams() {
      let params = sessionStorage.getItem('inventoryWorks') ? JSON.parse(sessionStorage.getItem('inventoryWorks')) : {}
      if(Object.keys(params).length) {
        this.page = params.page
        this.perPage = params.per_page
        this.statusSelected = params.status_equal
        this.shopSelected = params.shop_id_equal
      }
    },

    pageChange(val) {
      this.page = val;
      this.getData({page: val});
    },

    selectChangePerpage (val) {
      this.perPage =  val  ? parseInt(val) : ''
      this.getData({per_page: val});
    },

    goToCreatePage() {
      this.$router.push({path: 'inventory-works/create'})
    },

    goToSummaryPage(item) {
      this.$router.push({path: `inventory-works/${item.id}/summary`})
    },

    goToEditPage(item) {
      this.$router.push({path: `inventory-works/${item.id}/edit`})
    },

    handleConfirmArchive(item){
      this.$confirm(
          {
              title: '確認',
              message: `アーカイブを行いますか？`,
              button: {
                  no: 'いいえ',
                  yes: 'はい'
              },
              callback: confirm => {
                  if (confirm) {
                    inventoryWorkApiOther.archive(item).then(response => {
                        Vue.$toast.success('アーカイブに成功しました')
                        this.getData();
                    }).catch(error => {
                        Vue.$toast.error('アーカイブに失敗しました')
                    })
                  }
              }
          }
      )
    },

    getShops () {
      this.loading = true
      apiShop.all().then(response => {
        let shops = [];
        shops.push({label: '全て', value: ''})
        response.data.data.map(s =>{
          shops.push({label: s.name, value: s.id})
        })
        this.shopOptions = shops;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },
    selectStatusChange(v){
      this.statusSelected = v.target.value ? parseInt(v.target.value) : ""
      let params = this.getParams();
      this.getData(params);
    },
    selectShopChange(v){
      this.shopSelected = v.target.value ? parseInt(v.target.value) : ""
      let params = this.getParams();
      this.getData(params);
    },
    resetFilterData(){
      this.statusSelected = '';
      this.shopSelected = '';
      let params = this.getParams();
      this.getData(params);
    },
  }
}
</script>