const InventoryWork = {
  listWarehouseFields() {
    return [
      {key: 'id', label: 'ID'},
      {key: 'shop', label: '店舗'},
      {key: 'inventory_worked_at', label: '棚卸し日'},
      {key: 'status', label: 'ステータス'},
      {key: 'show', label: ''},
      {key: 'archive', label: ''},
    ]
  },
  listShopFields() {
    return [
      {key: 'id', label: 'ID'},
      {key: 'inventory_worked_at', label: '棚卸し日'},
      {key: 'status', label: 'ステータス'},
      {key: 'edit', label: ''},
      {key: 'show', label: ''},
    ]
  },
  summaryFields() {
    return [
      {key: 'item_code', label: '品番'},
      {key: 'name', label: '商品名'},
      {key: 'size', label: 'サイズ'},
      {key: 'color', label: '色'},
      {key: 'in_stock_count', label: 'システム上の個数'},
      {key: 'in_inventory_count', label: '実在個数'},
      {key: 'difference', label: '過不足'},
    ]
  },
}
export default InventoryWork
