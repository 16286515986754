import request from './request'

const inventoryWorkApi = {
  csvDownload(query) {
    return request({
      url: 'inventory-works/csv-download',
      method: 'get',
      params: query,
      responseType: 'blob'
    })
  },

  getInventoryWorkSummary(query) {
    return request({
      url: 'inventory-works/' + query.id + '/summary',
      method: 'get',
      data: query
    })
  },

  getItemStatuses(query) {
    return request({
      url:  'inventory-works/' + query.id + '/statuses',
      method: 'get',
      data: query
    })
  },

  carryOutInventoryWork(data) {
    return request({
        url: 'inventory-works/carry-out',
        method: 'put',
        data: data
    })
},

  archive(data) {
    return request({
        url: 'inventory-works/archive',
        method: 'post',
        data: data
    })
  }
}
export default inventoryWorkApi
